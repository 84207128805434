/* eslint-disable max-lines */
import { createBrowserRouter, Navigate, Outlet, RouterProvider, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { I18nContainer } from '@containers/i18n.container'
import { isLanguageValid, getDefaultLanguage } from '@libs/i18n/i18n'
import { ErrorBoundary } from '@components/ErrorBoundary'

const RootElement = (): JSX.Element => {
  const location = useLocation()

  const getLanguageFromPath = (): string | undefined => {
    const language = location.pathname.split('/')[1]
    return language
  }

  const getDefaultPath = (): string => {
    const slice = location.pathname.split('/').slice(2)
    const defaultPath = `/${getDefaultLanguage()}/${slice.join('/')}`

    return defaultPath
  }

  return isLanguageValid(getLanguageFromPath()) ? <Outlet /> : <Navigate to={getDefaultPath()} />
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootElement />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: ':language',
        element: <I18nContainer />,
        children: [
          {
            path: '',
            async lazy() {
              const { GuardMainPage } = await import('@modules/main/pages/main')
              return { Component: GuardMainPage }
            },
            children: [
              {
                path: 'projects',
                async lazy() {
                  const { GuardWelcomePage } = await import('@modules/project/pages/welcome')
                  return { Component: GuardWelcomePage }
                },
              },
              {
                path: 'projects/:projectId',
                async lazy() {
                  const { GuardMainPage } = await import('@modules/project/pages/main')
                  return { Component: GuardMainPage }
                },
                children: [
                  {
                    path: 'view',
                    async lazy() {
                      const { GuardMainPage } = await import('@modules/project/pages/view')
                      return { Component: GuardMainPage }
                    },
                  },
                  {
                    path: 'zoning',
                    async lazy() {
                      const { GuardZoningPage } = await import('@modules/project-zoning/pages/view')
                      return { Component: GuardZoningPage }
                    },
                  },
                  {
                    path: 'companies',
                    async lazy() {
                      const { GuardCompaniesPage } = await import('@modules/project-companies/pages/view')
                      return { Component: GuardCompaniesPage }
                    },
                  },
                  {
                    path: 'library',
                    async lazy() {
                      const { GuardLibraryPage } = await import('@modules/project-library/pages/view')
                      return { Component: GuardLibraryPage }
                    },
                    children: [
                      {
                        path: 'task-templates',
                        async lazy() {
                          const { TaskTemplatesPage } = await import('@modules/project-library/pages/task-templates')
                          return { Component: TaskTemplatesPage }
                        },
                      },
                      {
                        path: 'sequence-templates',
                        async lazy() {
                          const { SequenceTemplatesPage } = await import(
                            '@modules/project-library/pages/sequence-templates'
                          )
                          return { Component: SequenceTemplatesPage }
                        },
                      },
                      {
                        path: '',
                        Component: () => <Navigate to="task-templates" />,
                      },
                    ],
                  },
                  {
                    path: 'form-models',
                    async lazy() {
                      const { GuardFormModelsPage } = await import('@modules/project-form-models/pages/view')
                      return { Component: GuardFormModelsPage }
                    },
                  },
                  {
                    path: 'plans',
                    children: [
                      {
                        path: '',
                        async lazy() {
                          const { GuardPlansPage } = await import('@modules/project-plans/pages/view')
                          return { Component: GuardPlansPage }
                        },
                      },
                      {
                        path: 'editor/:planId',
                        async lazy() {
                          const { GuardPlanEditorPage } = await import('@modules/project-plans/pages/editor')
                          return { Component: GuardPlanEditorPage }
                        },
                      },
                    ],
                  },
                  {
                    path: 'documents',
                    async lazy() {
                      const { GuardDocumentsPage } = await import('@modules/project-documents/pages/view')
                      return { Component: GuardDocumentsPage }
                    },
                  },
                  {
                    path: 'users',
                    async lazy() {
                      const { GuardUsersPage } = await import('@modules/project-users/pages/view')
                      return { Component: GuardUsersPage }
                    },
                  },
                  {
                    path: 'planning',
                    async lazy() {
                      const { PlanningPage } = await import('@modules/project-planning/pages/view')
                      return { Component: PlanningPage }
                    },
                  },
                  {
                    path: 'forms',
                    children: [
                      {
                        path: '',
                        async lazy() {
                          const { GuardFormsPage } = await import('@modules/project-forms/pages/view')
                          return { Component: GuardFormsPage }
                        },
                      },
                      {
                        path: 'details/:formId',
                        async lazy() {
                          const { DetailsPage } = await import('@modules/project-forms/pages/details')
                          return { Component: DetailsPage }
                        },
                      },
                    ],
                  },
                  {
                    path: 'observations',
                    children: [
                      {
                        path: '',
                        async lazy() {
                          const { GuardFormsPage } = await import('@modules/project-observations/pages/view')
                          return { Component: GuardFormsPage }
                        },
                      },
                      {
                        path: 'details/:observationId',
                        async lazy() {
                          const { DetailsPage } = await import('@modules/project-observations/pages/details')
                          return { Component: DetailsPage }
                        },
                      },
                    ],
                  },
                  {
                    path: 'suivi',
                    async lazy() {
                      const { GuardSuiviPage } = await import('@modules/project-suivi/pages/view')
                      return { Component: GuardSuiviPage }
                    },
                  },
                  {
                    path: '',
                    Component: () => <Navigate to="view" />,
                  },
                ],
              },
              {
                path: 'settings',
                async lazy() {
                  const { GuardMainPage } = await import('@modules/settings/pages/main')
                  return { Component: GuardMainPage }
                },
                children: [
                  {
                    path: 'my_account',
                    async lazy() {
                      const { GuardMyAccountPage } = await import('@modules/settings/pages/my-account')
                      return { Component: GuardMyAccountPage }
                    },
                  },
                  {
                    path: 'subscriptions',
                    async lazy() {
                      const { GuardSubscriptionsPage } = await import('@modules/settings/pages/subscriptions')
                      return { Component: GuardSubscriptionsPage }
                    },
                  },
                  {
                    path: 'payment_methods',
                    async lazy() {
                      const { GuardPaymentMethodsPage } = await import('@modules/settings/pages/payment-methods')
                      return { Component: GuardPaymentMethodsPage }
                    },
                  },
                  {
                    path: 'invoices',
                    async lazy() {
                      const { GuardInvoicesPage } = await import('@modules/settings/pages/invoices')
                      return { Component: GuardInvoicesPage }
                    },
                  },
                ],
              },

              {
                path: 'admin',
                async lazy() {
                  const { GuardMainPage } = await import('@modules/admin/pages/main')
                  return { Component: GuardMainPage }
                },
                children: [
                  {
                    path: 'users',
                    async lazy() {
                      const { UsersPage } = await import('@modules/admin/pages/users')
                      return { Component: UsersPage }
                    },
                  },
                  {
                    path: 'projects',
                    async lazy() {
                      const { ProjectsPage } = await import('@modules/admin/pages/projects')
                      return { Component: ProjectsPage }
                    },
                  },
                ],
              },
              {
                path: '',
                Component: () => <Navigate to="projects" />,
              },
            ],
          },
          {
            path: 'sign-in',
            async lazy() {
              const { SignInPage } = await import('@modules/auth/pages/sign-in')
              return { Component: SignInPage }
            },
          },
          {
            path: 'sign-up',
            async lazy() {
              const { SignUpPage } = await import('@modules/auth/pages/sign-up')
              return { Component: SignUpPage }
            },
          },
          {
            path: 'join/:projectSharingId',
            async lazy() {
              const { JoinPage } = await import('@modules/sharing/pages/join')
              return { Component: JoinPage }
            },
          },
          {
            path: 'project-file-validation-request',
            async lazy() {
              const { ProjectFileValidationRequestPage } = await import(
                '@modules/project-file/pages/validation-request'
              )
              return { Component: ProjectFileValidationRequestPage }
            },
          },
          {
            path: 'project-file-validation-request-submit',
            async lazy() {
              const { ProjectFileValidationRequestSubmitPage } = await import(
                '@modules/project-file/pages/validation-request-submit'
              )
              return { Component: ProjectFileValidationRequestSubmitPage }
            },
          },
          {
            path: ':not_found',
            Component: () => {
              const { i18n } = useTranslation()
              return <Navigate to={`/${i18n.language}`} />
            },
          },
        ],
      },
    ],
  },
])

export const RouterContainer = (): JSX.Element => {
  return <RouterProvider router={router} />
}
